import { Component, OnInit } from '@angular/core';
import { Table } from 'src/app/model/table.model';
import { TableServiceService } from 'src/app/services/table-service.service';

@Component({
  selector: 'app-receiving',
  templateUrl: './receiving.component.html',
  styleUrls: ['./receiving.component.css']
})
export class ReceivingComponent implements OnInit {

  receptionsFile = './files/receiving/receptions.json';
  recYardsFile = './files/receiving/rec-yards.json';
  ypcAvgFile = './files/receiving/ypc-avg.json';
  ypgAvgFile = './files/receiving/ypg-avg.json';
  recTdsFile = './files/receiving/rec-tds.json';
  racFile = './files/receiving/rac.json';
  racAvgFile = './files/receiving/rac-avg.json';

  tableArr: Table[] = [];

  constructor(private tableService: TableServiceService) { }

  ngOnInit() {
    this.tableArr.push(this.tableService.setTableData(this.receptionsFile));
    this.tableArr.push(this.tableService.setTableData(this.recYardsFile));
    this.tableArr.push(this.tableService.setTableData(this.ypcAvgFile));
    this.tableArr.push(this.tableService.setTableData(this.ypgAvgFile));
    this.tableArr.push(this.tableService.setTableData(this.recTdsFile));
    this.tableArr.push(this.tableService.setTableData(this.racFile));
    this.tableArr.push(this.tableService.setTableData(this.racAvgFile));
  }

}
