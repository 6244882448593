import { Component, OnInit } from '@angular/core';
import { Table } from 'src/app/model/table.model';
import { TableServiceService } from 'src/app/services/table-service.service';

@Component({
  selector: 'app-rushing',
  templateUrl: './rushing.component.html',
  styleUrls: ['./rushing.component.css']
})
export class RushingComponent implements OnInit {

  rushAttemptsFile = './files/rushing/rush-attempts.json';
  rushYardsFile = './files/rushing/rush-yards.json';
  yprAvgFile = './files/rushing/ypr-avg.json';
  rushTdsFile = './files/rushing/rush-tds.json';
  ypgAvgFile = './files/rushing/ypg-avg.json';
  fumblesFile = './files/rushing/fumbles.json';
  brkTacklesFile = './files/rushing/brk-tckls.json';
  yacFile = './files/rushing/yards-after-contact.json';
  rush20File = './files/rushing/rush-20.json';

  tableArr: Table[] = [];

  constructor(private tableService: TableServiceService) { }

  ngOnInit() {
    this.tableArr.push(this.tableService.setTableData(this.rushAttemptsFile));
    this.tableArr.push(this.tableService.setTableData(this.rushYardsFile));
    this.tableArr.push(this.tableService.setTableData(this.yprAvgFile));
    this.tableArr.push(this.tableService.setTableData(this.rushTdsFile));
    this.tableArr.push(this.tableService.setTableData(this.ypgAvgFile));
    this.tableArr.push(this.tableService.setTableData(this.fumblesFile));
    this.tableArr.push(this.tableService.setTableData(this.brkTacklesFile));
    this.tableArr.push(this.tableService.setTableData(this.yacFile));
    this.tableArr.push(this.tableService.setTableData(this.rush20File));
  }

}
