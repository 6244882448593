import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Table } from '../model/table.model';

@Injectable({
  providedIn: 'root'
})
export class TableServiceService {

  constructor(private http: HttpClient) { }

  public getJSON(file: string): Observable<any> {
      return this.http.get(file);
  }

  public setTableData(file): Table {
    const t = new Table();
    this.getJSON(file).subscribe(data => {
      Object.assign(t, data);
    });

    return t;
  }
}
