import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { RecordsComponent } from './records/records.component';
import { HeaderComponent } from './header/header.component';
import { PassingComponent } from './records/passing/passing.component';
import { RushingComponent } from './records/rushing/rushing.component';
import { ReceivingComponent } from './records/receiving/receiving.component';
import { DefenseComponent } from './records/defense/defense.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  { path: '', component: RecordsComponent },
  { path: 'records', component: RecordsComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    RecordsComponent,
    HeaderComponent,
    PassingComponent,
    RushingComponent,
    ReceivingComponent,
    DefenseComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
