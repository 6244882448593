import { Component, OnInit } from '@angular/core';
import { Table } from 'src/app/model/table.model';
import { TableServiceService } from 'src/app/services/table-service.service';

@Component({
  selector: 'app-passing',
  templateUrl: './passing.component.html',
  styleUrls: ['./passing.component.css']
})
export class PassingComponent implements OnInit {

  qbRatingFile = './files/passing/qb-rating.json';
  passingYardsFile = './files/passing/passing-yards.json';
  passingTdsFile = './files/passing/passing-tds.json';
  passingIntsFile = './files/passing/passing-ints.json';
  timesSackedFile = './files/passing/times-sacked.json';
  completionsFile = './files/passing/completions.json';
  passAttemptsFile = './files/passing/pass-attempts.json';
  completionPctFile = './files/passing/completion-pct.json';
  ypaAvgFile = './files/passing/ypa-avg.json';
  ypgAvgFile = './files/passing/ypg-avg.json';

  tableArr: Table[] = [];

  constructor(private tableService: TableServiceService) {  }

  ngOnInit() {

     this.tableArr.push(this.tableService.setTableData(this.qbRatingFile));
     this.tableArr.push(this.tableService.setTableData(this.passingYardsFile));
     this.tableArr.push(this.tableService.setTableData(this.passingTdsFile));
     this.tableArr.push(this.tableService.setTableData(this.passingIntsFile));
     this.tableArr.push(this.tableService.setTableData(this.timesSackedFile));
     this.tableArr.push(this.tableService.setTableData(this.completionsFile));
     this.tableArr.push(this.tableService.setTableData(this.passAttemptsFile));
     this.tableArr.push(this.tableService.setTableData(this.completionPctFile));
     this.tableArr.push(this.tableService.setTableData(this.ypaAvgFile));
     this.tableArr.push(this.tableService.setTableData(this.ypgAvgFile));
  }
}
