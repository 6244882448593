import { Component, OnInit } from '@angular/core';
import { Table } from 'src/app/model/table.model';
import { TableServiceService } from 'src/app/services/table-service.service';

@Component({
  selector: 'app-defense',
  templateUrl: './defense.component.html',
  styleUrls: ['./defense.component.css']
})
export class DefenseComponent implements OnInit {

  tacklesFile = './files/defense/tackles.json';
  tflFile = './files/defense/tfl.json';
  sacksFile = './files/defense/sacks.json';
  intsFile = './files/defense/ints.json';
  intYardsFile = './files/defense/int-yards.json';
  intRetAvgFile = './files/defense/int-return-avg.json';
  passDefFile = './files/defense/pass-def.json';
  catchAllowedFile = './files/defense/catches-allowed.json';
  forcedFumblesFile = './files/defense/forced-fumbles.json';

  tableArr: Table[] = [];

  constructor(private tableService: TableServiceService) { }

  ngOnInit() {
    this.tableArr.push(this.tableService.setTableData(this.tacklesFile));
    this.tableArr.push(this.tableService.setTableData(this.tflFile));
    this.tableArr.push(this.tableService.setTableData(this.sacksFile));
    this.tableArr.push(this.tableService.setTableData(this.intsFile));
    this.tableArr.push(this.tableService.setTableData(this.intYardsFile));
    this.tableArr.push(this.tableService.setTableData(this.intRetAvgFile));
    this.tableArr.push(this.tableService.setTableData(this.passDefFile));
    this.tableArr.push(this.tableService.setTableData(this.catchAllowedFile));
    this.tableArr.push(this.tableService.setTableData(this.forcedFumblesFile));
  }


}
