import { Row } from './row.model';

export class Table {
    public colHeaders: String[];
    public rows: Row[];

    // constructor(
    //     public colHeaders: String[],
    //     public rows: Row[]) {}
}
